.App {
  text-align: center;
  width:600px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-bg{
  background-color:#08202C;
  diplay:block;
  color:#fff;
  padding-left: 16px;
  width:99%;
  height:100%;
}

.App-body{
  position: absolute;
  background-color:#001E3A;
  diplay:block;
  color:#fff;
  width:600px;
  padding-top: 5px;

}

.Movies-header{
  position: relative;
  left: 0%;
  right: -0.27%;
  top: 0%;
  bottom: 83.17%;
  width:600px;
  height: 250px;
  background-color:#08202E;
  background-image: url("images/background3.png");
}

.movieRow {
  position:relative;
}

.Movie-votes{
  position:absolute;
  z-index: 100;
  left: 27px;
  top: 15px;
  background: #01D277;
  border-radius: 6px;
}

.Movie-votes2{
  position:absolute;
  background: #01D277;
  border-radius: 6px;
  left:320px;
  padding-top:1px;
}

.Image-span{
  width:250px;
  height:350px;
  position:relative;
  border-radius: 15px;
  padding-left: 20px;
}

.MovieDetail-table{
  padding-left: 200px;
  align-content: center;
  width:300px;
}

.MovieDetail-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 30px;
  padding-top: 10px;
  padding-left: 20px;
  left: 178px;
}

.movieTitle{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 34px;
  /* or 175% */
  color: #B8D8E6;
}

.releaseDate{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 10px;
  /* identical to box height, or 117% */
  color: #A1D1E6;
}


.Title{
  position: absolute;
  height: 7px;
  left: 178px;
  right: 27px;
  top: 265.89px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  padding-top:150px;
  padding-left: 10px;
}

.Image-span:hover{
  cursor:pointer;
}

#movieTitle:hover{
  cursor:pointer;
}

.Details.span{
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 2px;
  line-height: 2px;
  color: #B8D8E6;
  padding-left: 100px;
}

.Overview.span{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #9FBBC7;
}

.Table.image{
  position: absolute;
  padding-left: 150px;
  width:600px;
  height: 600px;
}

.Image-overlap{
  position:absolute;
  left:50px;
  top:300px;
  width:250px;
  height:350px;
  border-radius:15px;
}

.Image-bg{
  position: relative;
  left: 0px;
  right: 0px;
  top: 0%;
  bottom: 76.75%;
  width:597px;
  height:400px;
}

h2{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */
  color: #E3F4FC;
  padding-left: 24px;
  padding-top: 8px;
  text-align: left;

}

.Search-bar{
  display:block;
  width:500px;
  padding-left: 15px;
  padding-right:25px;
  padding-top:8px;
  padding-bottom:8px;
  border-radius:22px;
  background:#ffffff;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.5);
  color:#01D277;
  height: 20px;
  left: 8.8%;
  right: 79.47%;
  top: calc(50% - 48px/2 - 364.5px);
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 16px;
}

.Search-span{
  padding-top: 65px;
  padding-left: 20px;
}

#Search-button {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  left: 100px;
  top: -28px;
  background-image: url("images/search.png");
}

#button-holder{
  background-color:#f1f1f1;
  border-top:thin solid #e5e5e5;
  box-shadow:1px 1px 1px 1px #e5e5e5;
  cursor:pointer;
  float:left;
  height:27px;
  margin:11px 0 0 0;
  text-align:center;
  width:50px;
  background-image: url("images/search.png");
}

.App-link {
  color: #09d3ac;
}

#button-holder img{
  margin:4px;
  width:20px;
}


.Small.title{
  position: relative;
  height: 16px;
  left: 4.27%;
  right: 60.53%;
  top: calc(50% - 16px/2 - 21.5px);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  color: #E6F7FF;
}

#search-icon{
  position: absolute;
  display: inline-block;
  padding-left: 240px;
  top: 208px;
  width: 1px;
  height: 20px;
  color:#01D277;
  border: white;
}


#search-icon:hover{
  cursor: pointer;
}

.Heading_image{
  padding-top: 65px;
}